// external Dependencies
import React, { useState } from 'react';
import styled from 'styled-components';

// gatsby Dependencies
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// internal dependencies
import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';

const StyledImg = styled(GatsbyImage)`
  min-width: 500px;
  max-width: 650px;
  border-radius: ${(props) => props.theme.elements.borderRadius};
  box-shadow: ${(props) => props.theme.effects.boxShadow};

  @media (max-width: 1200px) {
    min-width: 400px;
  }

  @media (max-width: 1100px) {
    min-width: 375px;
  }

  @media (max-width: 1024px) {
    min-width: 350px;
  }

  @media (max-width: 900px) {
    min-width: unset;
    width: 85vw;
    margin-bottom: 25px;
  }
`;

// component definition
const ProductVideosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: allContentfulVideoPageTemplate {
        nodes {
          metaDescription
          metaTitle
          pageTitle
          slug
          menuTitle
          videoPoster {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
            description
          }
        }
      }
    }
  `);

  const StyledGrid = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    gap: 50px 25px;
    padding: 50px;
    text-align: center;

    h5 {
      margin-top: 20px;
    }
  `;

  const videos = data.page.nodes.map((item, i) => (
    <Link to={`/product-videos/${item.slug}`} key={i}>
      <StyledImg
        objectFit='contain'
        image={item.videoPoster.gatsbyImageData}
        alt=''
      />
      <h5>{item.menuTitle}</h5>
    </Link>
  ));

  return (
    <>
      <SEO description='tbd' title='Product Videos' />
      <Hero short headline='Product Videos' buttonColor='blue' />
      <StyledGrid>{videos}</StyledGrid>
    </>
  );
};

export default ProductVideosPage;
